// hero-slider

import Swiper from 'swiper/swiper-bundle.min.mjs';
import "swiper/swiper-bundle.min.css";



const swiperEvent = new Swiper('.event-slider', {
	slidesPerView: "1",
	loop:true,
	speed: 900,
	spaceBetween: 20,
	pagination: { 
		el: '.swiper-pagination-events', clickable: true 
	},
	breakpoints: {
        640: {
		loop:true,
          slidesPerView: 1,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 2,
		  loop:true,
		  spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
		  loop:true,
		  spaceBetween: 20,
        },
		1280: {
			loop:false,
			slidesPerView: 4,
			spaceBetween: 20,
		},
		1440: {
			loop:false,
			slidesPerView: 4,
			spaceBetween: 20,
		},
		1680: {
			loop:false,
			slidesPerView: 5,
			spaceBetween: 20,
		},
		1920: {
			loop:false,
			slidesPerView: 5,
			spaceBetween: 20,
		},
		2560: {
			loop:false,
			slidesPerView: 5,
			spaceBetween: 20,
		},
    }
});