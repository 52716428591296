// hero-slider

import Swiper from 'swiper/swiper-bundle.min.mjs';
import "swiper/swiper-bundle.min.css";


const swiperHero = new Swiper('.hero-slider', {
	// Optional parameters
	direction: 'horizontal',
	loop: true,
  
	// If we need pagination
	pagination: {
	  el: '.swiper-pagination',
	  clickable: true
	},
  
	// Navigation arrows
	navigation: {
	  nextEl: '.swiper-button-next',
	  prevEl: '.swiper-button-prev'
	},

	// Keyboard control
	keyboard: {
        enabled: true,
    }
  });