// hero-slider

import Swiper from 'swiper/swiper-bundle.min.mjs';
import "swiper/swiper-bundle.min.css";


const swiperHero = new Swiper('.product-slider', {
	// Optional parameters
	direction: 'vertical',
	loop: true,
	speed: 900,
	effect: "fade",

	autoplay: {
		delay: 5000,
	  },
  
	// If we need pagination
	pagination: {
	  el: '.swiper-pagination',
	  clickable: true
	},
  
	// Navigation arrows
	// navigation: {
	//   nextEl: '.swiper-button-next',
	//   prevEl: '.swiper-button-prev'
	// },

	// Keyboard control
	keyboard: {
        enabled: true,
    }
  });