// Standard
//import "bootstrap"; // nicht so! nur importieren, was wir brauchen

//so:
import Dropdown from 'bootstrap/js/dist/dropdown'
import Collapse from 'bootstrap/js/dist/collapse'
import Modal from 'bootstrap/js/dist/modal'

// or, specify which plugins you need:
//import { Dropdown, Collapse } from 'bootstrap'

import Mmenu from 'mmenu-js';

import gsap from "gsap";

import ScrollTrigger from "gsap/ScrollTrigger";



gsap.registerPlugin(ScrollTrigger)


// if there are objects that may get inline styles added (like via tweens) that should get reverted, use ScrollTrigger.saveStyles() initially so that the current inline styles are saved for later reversion. It's not always necessary, but it goes well with ScrollTrigger.matchMedia() so we figured it'd make sense to show it here (it's not needed in this demo)
ScrollTrigger.saveStyles(".mobile, .desktop");

/*** Different ScrollTrigger setups for various screen sizes (media queries) ***/
ScrollTrigger.matchMedia({
	
	// desktop
	"(min-width: 1280px)": function() {
		const wrapper = document.querySelector(".benefits-slider");

function getScrollAmount() {
  let horizontalWidth = wrapper.scrollWidth;
  return -(horizontalWidth - window.innerWidth);
}

const tween = gsap.to(wrapper, {
  x: getScrollAmount,
  duration: 2,
  ease: "none",
});



ScrollTrigger.create({
  trigger:".benefits-container",
  start:"top 20%",
  end: () => `+=${getScrollAmount() * -1}`,
  pin:"main",
  animation:tween,
  scrub:1,
  invalidateOnRefresh:true,
  markers:false,
});

ScrollTrigger.observe({
  target: ".benefits-container",
  type: "touch, pointer", // comma-delimited list of what to listen for ("wheel,touch,scroll,pointer")
  ease: "none",
  

  onDrag: (self) => {
    console.log(self.deltaX);
    console.log(self.deltaY);
    let horPos = Math.sqrt(self.deltaX * self.deltaX)
    let verPos = Math.sqrt(self.deltaY * self.deltaY)
    if(horPos > verPos) {
      gsap.to(window, {
        scrollTo: {
          y: `-=${self.deltaX * 0 }`,
        }
      });
    } else {
      gsap.to(window, {
        scrollTo: {
          y: `-=${self.deltaY * 2 }`,
        }
      });
    }
  }
});
    
  }, 
  

  
	// all 
	"all": function() {
		// ScrollTriggers created here aren't associated with a particular media query,
		// so they persist.
	}
  
});









// Modules

import "./modules/module-hero-slider/module-hero-slider.js";  
import "./modules/module-product-slider/module-product-slider.js";
import "./modules/module-event-slider/module-event-slider.js";

// Custom
import "./custom.js";




//import "./modules/uo-accessibility/uo-accessibility.js";  