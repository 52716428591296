document.addEventListener(
	"DOMContentLoaded", () => {
		new Mmenu( "#menu", {
		   "offCanvas": {
			  "position": "right-front"
		   },
		   navbar: {
			title: ""
		  }
		});
	}
);

// fittext

import fitty from 'fitty';
window.fitty = fitty.default;

document.addEventListener("DOMContentLoaded", function(){
    

	fitty('.kicker h2', {
		
		sync: true,
		maxSize:230,
	});
});


const chatButton = document.getElementById("chat-button");
const chatContainer = document.getElementById("chatContainer");
const minimizeButton = document.getElementById("minimize-button");
const chatInput = document.getElementById("chat-input");
const chatMessages = document.getElementById("conversation-group");
const sendButton = document.getElementById("SentButton");

if (chatButton) {
	chatButton.addEventListener("click", function () {
		if (chatContainer) {
			chatContainer.classList.add("open");
			chatButton.classList.add("clicked");
		}
	});
}

if (minimizeButton) {
	minimizeButton.addEventListener("click", function () {
		if (chatContainer) {
			chatContainer.classList.remove("open");
			chatButton.classList.remove("clicked");
		}
	});
}

function createMessage(message, isUser = true) {
	const newMessage = document.createElement('div');
	newMessage.classList.add(isUser ? 'sentText' : 'botText');
	newMessage.textContent = message;
	chatMessages.appendChild(newMessage);
	return newMessage;
}

function chatbotResponse() {
	const messages = ["Hello!", "How can I assist you?", "Let me know if you have any further questions"];
	const randomIndex = Math.floor(Math.random() * messages.length);
	const message = messages[randomIndex];
	const botMessage = createMessage(message, false);
	botMessage.scrollIntoView();
}





    


//var chmodr = require('chmodr');
//   chmodr('/runsurance', 0o777, (err) => {
//     if (err) {
//       console.log('Failed to execute chmod', err);
//     } else {
//       console.log('Success');
//     }
//   });